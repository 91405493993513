body {
  margin: 0;
  font-family: 'EB Garamond', 'Courier New', Courier, monospace;
  background: rgb(60,4,134);
  background: linear-gradient(90deg, rgba(60,4,134,1) 11%, rgba(255,175,216,1) 100%);
}

button {
  cursor: pointer;
}

